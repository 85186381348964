namespace StatBanana.Web.Client.Services

open System

open Fable.Import
open Fable.PowerPack

open Thoth.Json

open StatBanana.Domain

/// <summary>
///     Service responsible for saving and loading items in local storage.
/// </summary>
module LocalStorageService =

    module private Keys =
        [<Literal>]
        let legacyNotification = "legacy_notification_received"
        [<Literal>]
        let subscriptionOrder = "subscription_order"
        [<Literal>]
        let signedUpDate = "signed_up_date"

    /// Decoders for adapting values from localstorage into domain objects.
    module private Decoders =
        let legacyNotification = Thoth.Json.Decode.Auto.generateDecoder<bool>()
        let subscriptionOrder = Thoth.Json.Decode.Auto.generateDecoder<Order>()
        let signedUpDate = Thoth.Json.Decode.Auto.generateDecoder<DateTimeOffset>()

    let private handleRetrievalError (result : Result<'t, string>) : 't option =
        match result with
        | Ok value ->
            Some value
        | Error error ->
            JS.console.warn("BrowserLocalStorage.load: " + error)
            None

    /// <summary>
    ///     Load an item from local storage.
    /// </summary>
    ///
    /// <param name="decoder">
    ///     Thoth decoder used to decode the item from local storage.
    /// </param>
    ///
    /// <param name="key">
    ///     The key of the item to load.
    /// </param>
    let private load (decoder : Decode.Decoder<'t>) (key : string) : 't option =
        BrowserLocalStorage.load decoder key
        |> handleRetrievalError

    /// <summary>
    ///     Save an item in local storage.
    /// </summary>
    ///
    /// <param name="key">
    ///     The key to save the item under.
    /// </param>
    ///
    /// <param name="item">
    ///     The item to save.
    /// </param>
    let private save (key : string) (item : 't) : unit  =
        BrowserLocalStorage.save key item

    /// <summary>
    ///     Delete a key from local storage.
    /// </summary>
    ///
    /// <param name="key">
    ///     The key to delete.
    /// </param>
    let private delete (key : string) : unit  =
        BrowserLocalStorage.delete key

    // Check if user has receieved a legacy product notification yet
    let loadLegacyNotificationReceived () : bool option =
        load
            Decoders.legacyNotification
            Keys.legacyNotification

    // Save legacy notification status to storage
    let saveLegacyNotificationReceived () : unit =
        save
            Keys.legacyNotification
            true

     /// Save a subscription order to local storage
    let saveSubscriptionOrder (order : Order) : unit =
        save
            Keys.subscriptionOrder
            order

     /// Delete subscription order from local storage
    let deleteSubscriptionOrder () : unit =
        delete
            Keys.subscriptionOrder

    /// Load the subscription order from local storage
    let loadSubscriptionOrder () : Order option =
        load
            Decoders.subscriptionOrder
            Keys.subscriptionOrder

    /// Save user signed up date to local storage
    let saveSignedUpDate (date : DateTimeOffset) : unit =
        save
            Keys.signedUpDate
            date

    /// Load the user signed up date from local storage
    let loadSignedUpDate () : DateTimeOffset option =
        load
            Decoders.signedUpDate
            Keys.signedUpDate