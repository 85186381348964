/// Input to toggle building state
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Atoms.BuildingStateToggle

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import
open Fable.Import.React

open Fulma

open StatBanana.Web.Client.Domain.Strategiser

type Styles = { input : string }

/// <summary>
///     Renders an input field that allows for toggling building state
/// </summary>
///
/// <param name="buildingState">
///    The current building state
/// </param>
/// <param name="onToggle">
///    The function to call when a toggle occurs
/// </param>
let toggle
    (buildingState : BuildingState)
    (onToggle : BuildingState -> unit) : ReactElement =
    let styles : Styles = importAll "./BuildingStateToggle.sass"
    let onChangeHandler (event : FormEvent) =
        match event.Value |> BuildingState.fromString with
        | Some state -> state |> onToggle
        | None ->
            Browser.console.warn "Invalid building state input"
    let radioProps value currentBuildingState : IHTMLProp list =
        [ Checked (currentBuildingState = value)
          OnChange onChangeHandler
          Value value ]
    Field.div [] [
        Label.label [] [ str "Building State" ]
        Control.div [] [
            Radio.radio [] [
                Radio.input [ Radio.Input.CustomClass styles.input
                              Radio.Input.Props
                                  (radioProps
                                       BuildingState.NotDestroyed
                                       buildingState) ]
                BuildingState.NotDestroyed
                |> BuildingState.getName
                |> str
            ]
            Radio.radio [] [
                Radio.input [ Radio.Input.CustomClass styles.input
                              Radio.Input.Props
                                  (radioProps
                                       BuildingState.Destroyed
                                       buildingState) ]
                BuildingState.Destroyed
                |> BuildingState.getName
                |> str
            ]
        ]
    ]