[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Pages.LandingPage

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import
open Fable.Import.React

open Elmish
open Fulma

open StatBanana.Web.Client.Components.Molecules
open StatBanana.Web.Client.Components.Organisms
open StatBanana.Web.Client.Components.Templates
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Services.LocalStorageService

type Styles = { centeredContent : string; close : string }

type Model =
    { ReceivedLegacyNotification : bool option
      UserProfile : UserProfile option }

type Msg =
    | UserProfileDoesNotExist of AuthenticatedUser
    | FetchUserProfileError of exn
    | UserProfileFetched of UserProfile

module Cmd =

    let fetchUserProfile
        (app : AppConfig)
        (authenticatedUser : AuthenticatedUser)
        : Cmd<Msg> =

        let ofSuccess result =
            match result with
            | Some firestoreUser ->
                UserProfileFetched firestoreUser
            | None ->
                UserProfileDoesNotExist authenticatedUser
        let ofError (exn : exn) =
            FetchUserProfileError exn
        Cmd.ofPromise
            app.userStoreService.getUser
            authenticatedUser.id
            ofSuccess
            ofError

let init
    (app : AppConfig)
    (optionalUser : AuthenticatedUser option)
    : Model * Cmd<Msg> =

    let cmd =
        match optionalUser with
        | Some user -> Cmd.fetchUserProfile app user
        | None -> Cmd.none

    // Retreive retrieved status from localStorage
    { ReceivedLegacyNotification = (loadLegacyNotificationReceived ())
      UserProfile = None }, cmd

/// <summary>
///     Updates the model in response to a message.
/// </summary>
///
/// <param name="msg">
///     The message to action.
/// </param>
///
/// <param name="model">
///     The model prior to actioning the message.
/// </param>
let update
    (app : AppConfig)
    (msg : Msg)
    (currentModel : Model)
    : Model * Cmd<Msg> =

    match msg with
    | UserProfileFetched userProfile ->
        let newModel =
            { currentModel with UserProfile = userProfile |> Some }
        newModel, Cmd.none
    | UserProfileDoesNotExist authenticatedUser ->
        let newModel =
            { currentModel with UserProfile = None }
        newModel, Cmd.none
    | FetchUserProfileError _ ->
        let newModel =
            { currentModel with UserProfile = None }
        newModel, Cmd.none

let private sections : HeroPageTemplate.Section list =
    let motivator =
        Content.content [ Content.Modifiers
                              [ Modifier.TextAlignment
                                    (Screen.All, TextAlignment.Centered) ] ] [
            Heading.h3 [] [ str "From your first scrim to the big tournament" ]
            p [] [ str
               "Everything you need to get your team running on the same page"
            ]
        ]
    let renderShowcase feature =
        HeroPageTemplate.ContainedSection [
            FeatureShowcase.animatedShowcase feature
        ]
    let landingHook =
        Columns.columns [] [
            Column.column [] [ FlashyCTA.Horizontal.dota2 () ]
            Column.column [] [ FlashyCTA.Vertical.overwatch () ]
        ]

    [ HeroPageTemplate.ContainedSection []
      HeroPageTemplate.ContainedSection [ motivator ]
      renderShowcase LandingPage.Feature.HighQualityMaps
      renderShowcase LandingPage.Feature.VisualPlanning
      renderShowcase LandingPage.Feature.TimelineAnimation
      renderShowcase LandingPage.Feature.CollaborativeSessions
      HeroPageTemplate.ContainedSection [ landingHook ] ]

/// <summary>
///     Defines the view to render based on the current state.
/// </summary>
///
/// <param name="model">
///     The current state or model.
/// </param>
///
/// <param name="dispatch">
///     The function that allows the view to dispatch Messages.
/// </param>
let view
    (optionalUser : AuthenticatedUser option)
    (model : Model)
    (dispatch : Msg -> unit)
    (onSignOut : unit -> unit) =

    let styles : Styles = importAll "./Landing.sass"

    let closeNotification (event : MouseEvent) =
        // save received status to localStorage
        saveLegacyNotificationReceived ()
        |> ignore

        let notificationContainer =
            "legacy-notification-container"
            |> Browser.document.getElementById
        notificationContainer.removeChild event.target?parentNode
        |> ignore

    let renderedLegacyNotification =
        match model.ReceivedLegacyNotification with
        | Some _ ->
            nothing
        | None ->
            let notificationProps : Notification.Option list =
                [ Notification.Color IsWarning
                  Notification.Option.CustomClass styles.centeredContent
                  Notification.Option.Modifiers [ Modifier.IsMarginless ] ]
            let deleteButtonProps : GenericOption list =
                [ GenericOption.CustomClass styles.close
                  GenericOption.Props [ OnClick closeNotification ] ]
            div [ Id "legacy-notification-container"] [
                Notification.notification notificationProps [
                    div [] [
                        str "Are you here for Statbanana Overwatch? Check out the legacy site at "
                        a [ Href "https://overwatch.statbanana.com" ] [ str "https://overwatch.statbanana.com" ]
                    ]
                    Notification.delete deleteButtonProps []
                ]
            ]

    fragment [] [
        renderedLegacyNotification
        HeroPageTemplate.template
            (LandingHero.hero optionalUser model.UserProfile onSignOut)
            sections
            (Footer.footer ())
    ]