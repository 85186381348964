namespace StatBanana.Web.Client.Domain

open System

open Fable.Import

open StatBanana.Web.Client.Domain

/// Exception indicating that a firestore user operation failed.
type UserOperationFailed (msg : string) =
    inherit Exception (msg)

/// A service responsible for dealing with firestore users.
type UserStoreService =
    { getUser : string -> JS.Promise<UserProfile option> }
