namespace StatBanana.Web.Client

open Elmish

open StatBanana.Web.Client.Cmd
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Router

/// Defines elmish update logic for AuthMsg events
module AuthMsgHandler =
    let private handleUserSignedIn app user model =
        // Send user UID to analytics
        let commonCmds = [ AnalyticsCmd.userIdAvailable app user.id ]

        match model.PageModel with
        // Handle when user is signed in on auth page
        | AuthPageModel { Destination = destination } ->
            { model with User = user |> Authenticated },
            Cmd.batch (RouterCmd.navigateTo (destination) :: commonCmds)
        // Handle when user is signed in on other pages
        | _ ->
            { model with User = user |> Authenticated }, Cmd.batch commonCmds

    let private handleUserSignedOut model =
        let redirectCmd =
            match model.Router.route with
            // Handle when user is signed out on pages that require auth
            | Some currentRoute when Route.isAuthRequired currentRoute ->
                let destination =
                    match model.User with
                    // Handle signed out event on auth service initialisation
                    | AuthServiceNotInitialised
                    | NotAuthenticated ->
                        let postLoginRoute =
                            currentRoute
                            |> Some
                        Route.SignUp postLoginRoute
                    // Handle user signout action from an authenticated page
                    | Authenticated _ ->
                        Route.LogIn None

                RouterCmd.navigateTo destination

            // Handle when user is signed out on pages that don't require auth
            | _ ->
                Cmd.none

        { model with User = NotAuthenticated }, redirectCmd

    let private subscribeToTokenRefresh (app : AppConfig) user =
        let sub dispatch =
            let onRequireRefresh () =
                (TokenRefreshRequired user)
                |> dispatch
            let onError exn =
                (ListenForTokenRefreshError exn)
                |> dispatch
            app.authService.isTokenRefreshRequired
                user
                onRequireRefresh
                onError

        Cmd.ofSub sub

    /// Update application state in response to an AuthMsg.
    let update
        (app : AppConfig)
        (msg : AuthMsg)
        (model : Model)
        : Model * Cmd<Msg> =

        match msg with
        | UserSignedIn user ->
            let newModel, cmd =
                handleUserSignedIn app user model
                |> Router.completeDeferredUrlUpdate app

            newModel, Cmd.batch [ cmd; Cmd.map AuthMsg (subscribeToTokenRefresh app user) ]
        | UserSignedOut ->
            handleUserSignedOut model
            |> Router.completeDeferredUrlUpdate app
        | UserRequestedSignOut _ ->
            model, AuthCmd.signOutUser app

        | TokenRefreshRequired user ->
            model, AuthCmd.refreshToken app user

        | ListenForTokenRefreshError exn ->
            model, Cmd.none
