/// Sidebar tab
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Atoms.SidebarTab

open System

open Fable.Core.JsInterop
open Fable.FontAwesome
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React
open Fable.Import

open Fulma.Extensions.Wikiki

open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Import

type Styles =
    { disabled : string
      opened : string
      openLeft : string
      openRight : string
      selectedVariant : string
      tab : string
      toolVariantMenu : string }
let private styles : Styles = importAll "./SidebarTab.sass"

type SidebarTabData =
    { disabled : bool
      icon : ReactElement
      id : SidebarTab }

/// <summary>
///     Renders a sidebar tab
/// </summary>
///
/// <param name="isOpen">
///     Whether this particular content pane is currently open
/// </param>
/// <param name="onClick">
///     Function to call when the tab is clicked
/// </param>
/// <param name="tab">
///     The Tab to render
/// </param>
let tab
    (isOpen : bool)
    (onClick : SidebarTab -> unit)
    (tab : SidebarTabData)
    (openDirection : SidebarOpenDirection ) : ReactElement =
    let onClickHandler _ =
        if not tab.disabled then onClick tab.id
    let tooltipSideClass =
        match openDirection with
        | SidebarOpenDirection.Left ->
            Tooltip.IsTooltipLeft
        | SidebarOpenDirection.Right ->
            Tooltip.IsTooltipRight
    let className =
        ClassNames.classNames [
            ("SidebarTab", true)
            (styles.tab, true)
            (Tooltip.ClassName, true)
            (tooltipSideClass, true)
            (styles.opened, isOpen)
        ]
    li [ ClassName className
         Data ("tooltip", SidebarTab.getTooltipText tab.id)
         Disabled tab.disabled
         Id "SidebarTab"
         OnClick onClickHandler
         Role "tab" ] [
        tab.icon
    ]

/// <summary>
///     Renders a sidebar tool tab
/// </summary>
///
/// <param name="onClick">
///     Function to call when the tool is selected
/// </param>
/// <param name="tab">
///     The Tab to render
/// </param>
let toolTab
    (tool : SidebarTool)
    (isSelected : bool)
    (onClick : SidebarTool -> unit)
    (tab : SidebarTabData)
    (openDirection : SidebarOpenDirection ) : ReactElement =
    let styles : Styles = importAll "./SidebarTab.sass"
    let onClickHandler _ =
        onClick tool
    let tooltipSideClass =
        match openDirection with
        | SidebarOpenDirection.Left ->
            Tooltip.IsTooltipLeft
        | SidebarOpenDirection.Right ->
            Tooltip.IsTooltipRight
    let className =
        ClassNames.classNames [
            (styles.tab, true)
            (Tooltip.ClassName, true)
            (tooltipSideClass, true)
            (styles.opened, isSelected)
        ]
    li [ ClassName className
         Data ("tooltip", SidebarTool.getTooltipText tool)
         Disabled tab.disabled
         OnClick onClickHandler
         Role "tab" ] [
        tab.icon
    ]

/// <summary>
///     Renders a sidebar tool tab with a sub-menu
/// </summary>
///
/// <param name="openDirection">
///     The direction the sub-menu should open
/// </param>
/// <param name="tools">
///     The list of tools to populate the sub-menu
/// </param>
/// <param name="selectedTool">
///     The currently selected tool (default = Panning)
/// </param>
/// <param name="isSelected">
///     Flag for if one of the variants is selected
/// </param>
/// <param name="onClick">
///     Function to call when the tool is selected
/// </param>
/// <param name="tab">
///     The Tab to render
/// </param>
let toolTabWithVariants
    (openDirection : SidebarOpenDirection)
    (tools : SidebarTool list)
    (selectedTool : SidebarTool)
    (isSelected : bool)
    (onClick : SidebarTool -> unit)
    (tab : SidebarTabData) : ReactElement =
    let tabId = "tool-" + (Guid.NewGuid ()).ToString ()
    let getVariantIcon (tool : SidebarTool) =
        match tool with
        | Measuring StraightMeasuring ->
            Fa.i [ Fa.Solid.Ruler ] []
        | Measuring RadialMeasuring ->
            Fa.i [ Fa.Solid.DraftingCompass ] []
        | Drawing
        | Erasing
        | Panning
        | Redoing
        | Texting
        | Undoing ->
            nothing
    let onClickVariantHandler _ tool =
        let tabElement =
            Browser.document.getElementById tabId
        let tabElementContent = tabElement.lastElementChild
        tabElement.innerHTML <-
            ReactDomServer.renderToString (getVariantIcon tool)
        tabElement.appendChild tabElementContent |> ignore
        onClick tool
    let renderVariant tool =
        let className =
            ClassNames.classNames [
                (styles.tab, true)
                (Tooltip.ClassName, true)
                (Tooltip.IsTooltipTop, true)
                (styles.selectedVariant, selectedTool = tool)
            ]
        let variantTooltipText = SidebarTool.getTooltipText tool
        li [ ClassName className
             Data ("tooltip", variantTooltipText)
             Disabled (tab.disabled)
             OnClick (fun event -> onClickVariantHandler event tool)
             Role "tab" ] [
            getVariantIcon tool
        ]
    let renderedVariants = List.map renderVariant tools
    let className =
        ClassNames.classNames [
            (styles.tab, true)
            (styles.opened, isSelected)
            (styles.openLeft, openDirection = SidebarOpenDirection.Left)
            (styles.openRight, openDirection = SidebarOpenDirection.Right)
        ]
    let subMenuClassName =
        ClassNames.classNames [
            (styles.toolVariantMenu, true)
            (styles.openLeft, openDirection = SidebarOpenDirection.Left)
            (styles.openRight, openDirection = SidebarOpenDirection.Right)
        ]
    li [ ClassName className
         Disabled (tab.disabled)
         Id tabId
         Role "tab" ] [
        tab.icon
        div [ ClassName subMenuClassName ] [
            (ofList renderedVariants)
        ]
    ]

