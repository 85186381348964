namespace StatBanana.Domain

open System

/// A user request to enter or leave a subscription
type Order =
    { createdAt : DateTimeOffset
      game : Game
      lineItem : LineItem }
module Order =
    let getDefault () : Order =
        { createdAt = DateTimeOffset.Now
          game = Game.Dota2
          lineItem = LineItem.getDefault () }