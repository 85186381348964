/// Aggregates the Web.Client domain
namespace StatBanana.Web.Client.Domain

open StatBanana.Web.Client.Pages

type PageModel =
    | ArticlePageModel of ArticlePage.Model
    | AuthPageModel of AuthPage.Model
    | CheckoutPageModel of CheckoutPage.Model
    | ClientErrorPageModel of ClientErrorPage.Model
    | ImagesPageModel of ImagesPage.Model
    | LandingPageModel of LandingPage.Model
    | LoadingPageModel of LoadingPage.Model
    | NewSubscriptionSuccessPageModel of NewSubscriptionSuccessPage.Model
    | NotFoundPageModel of NotFoundPage.Model
    | PricingPageModel of PricingPage.Model
    | PrivacyPageModel of PrivacyPage.Model
    | StrategiserPageModel of StrategiserPage.Model
    | TermsPageModel of TermsPage.Model
    | UserSettingsPageModel of UserSettingsPage.Model

/// State maintained by the router
type RouterModel =
    { /// Current route, or None if the current URL is not a valid route
      route : Route option
      /// Has the current route been initialised?
      ///
      /// Set to true when the router has completed all initialisation for the
      /// current route.
      ///
      /// Set to false if the router has deferred initialisation of the current
      /// route until some other action completes (e.g. waiting for the auth
      /// service to initialise).
      routeInitialised : bool }

/// Captures our knowledge of the current user
type CurrentUser =
    /// We don't know anything until the AuthService has been initialised
    | AuthServiceNotInitialised
    /// There is not currently authenticated user
    | NotAuthenticated
    /// The currently authenticated user
    | Authenticated of AuthenticatedUser
module CurrentUser =
    /// <summary>
    ///     Convert to an optional AuthenticatedUser.  This is useful for cases
    ///     where we don't need to differentiate NotAuthenticated from
    ///     AuthServiceNotInitialised.
    /// </summary>
    ///
    /// <param name="user">The user to convert to an option.</param>
    ///
    /// <returns>Optional authenticated user</returns>
    let toAuthenticatedUserOption
        (user : CurrentUser) : AuthenticatedUser option =
        match user with
        | Authenticated authedUser ->
            Some authedUser
        | AuthServiceNotInitialised
        | NotAuthenticated ->
            None

/// <summary>
/// The client application state model.
/// </summary>
///
/// <remarks>
/// Contains the model for the current page, previous page and details of the
/// current logged in user
/// </remarks>
type Model =
    { DeploymentEnvironment : DeploymentEnvironment
      PageModel : PageModel
      Router : RouterModel
      User : CurrentUser }

/// <summary>
/// Defines the events that can occur while the application is running.
/// </summary>
///
/// The state of the application changes *only* in reaction to these events.
type Msg =
    | AuthMsg of AuthMsg
    | GlobalMsg of GlobalMsg

    /// Message type to indicate that initialisation has completed for a new route
    | RouteInitialised

    // Page-specific message types
    | ArticlePageMsg of ArticlePage.Msg
    | AuthPageMsg of AuthPage.Msg
    | CheckoutPageMsg of CheckoutPage.Msg
    | ClientErrorPageMsg of ClientErrorPage.Msg
    | ImagesPageMsg of ImagesPage.Msg
    | LandingPageMsg of LandingPage.Msg
    | LoadingPageMsg of LoadingPage.Msg
    | NotFoundPageMsg of NotFoundPage.Msg
    | NewSubscriptionSuccessPageMsg of NewSubscriptionSuccessPage.Msg
    | PricingPageMsg of PricingPage.Msg
    | PrivacyPageMsg of PrivacyPage.Msg
    | StrategiserPageMsg of StrategiserPage.Msg
    | TermsPageMsg of TermsPage.Msg
    | UserSettingsPageMsg of UserSettingsPage.Msg
