module StatBanana.Web.Client.Components.Molecules.FeatureShowcase

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open Fulma

open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Domain

type Styles =
    { active : string
      container : string
      feature : string
      showcase : string
      video : string }
let private styles : Styles = importAll "./FeatureShowcase.sass"

let private getShowcaseForFeature (feature : LandingPage.Feature) =
    let renderVideo videoName =
        let assetDir = "assets/landing"
        video [ AutoPlay true
                ClassName styles.video
                Loop true
                Muted true ] [
            source [ Src (assetDir + "/" + videoName + ".webm")
                     Type "video/webm" ]
            source [ Src (assetDir + "/" + videoName + ".mp4")
                     Type "video/mp4" ]
        ]

    match feature with
    | LandingPage.Feature.CollaborativeSessions ->
        renderVideo "collaborativesessions"
    | LandingPage.Feature.HighQualityMaps ->
        renderVideo "highqualitymaps"
    | LandingPage.Feature.TimelineAnimation ->
        renderVideo "timelineanimation"
    | LandingPage.Feature.VisualPlanning ->
        renderVideo "visualplanning"

/// <summary>
///     Renders a nice, clean feature showcase.
/// </summary>
///
/// <param name="feature">
///     The feature to be showcased.
/// </param>
let showcase (feature : LandingPage.Feature) : ReactElement =
    div [ ClassName styles.container ] [
        div [ ClassName styles.showcase ] [
            getShowcaseForFeature feature
        ]
        div [ ClassName styles.feature ] [
            Heading.h4 [] [
                feature |> LandingPage.Feature.getLabel |> str
            ]
            p [] [
                feature |> LandingPage.Feature.getBlurb |> str
            ]
        ]
    ]

/// <summary>
///     Renders a nice, clean feature showcaser but animated.
/// </summary>
///
/// <param name="feature">
///     The feature to be showcased.
/// </param>
let animatedShowcase (feature : LandingPage.Feature) : ReactElement =
    AnimateOnScroll.aos [ AnimateOnScroll.Prop.AnimationType
                              AnimateOnScroll.Animation.FadeUp
                          AnimateOnScroll.Prop.PersistAfterScroll true ] [
        showcase feature
    ]