[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Pages.TermsPage

open Fable.Helpers.React

open Elmish
open Fulma

open StatBanana.Web.Client.Components.Templates
open StatBanana.Web.Client.Domain

/// Application state passed down to this page
type Model = unit

/// Events/actions that can be dispatched by this page.
type Msg = unit

/// Run on initialisation. Page is static, does nothing.
let init () : Model * Cmd<Msg> =
    (), Cmd.none

/// <summary>
///     Updates the model in response to a message.
/// </summary>
///
/// <param name="msg">
///     The message to action.
/// </param>
///
/// <param name="currentModel">
///     The model prior to actioning the message.
/// </param>
let update
    (app : AppConfig)
    (msg : Msg)
    (currentModel : Model) : Model * Cmd<Msg> =
    match currentModel, msg with
    | (), () -> currentModel, Cmd.none

/// <summary>
///     Defines the view to render based on the current state.
/// </summary>
///
/// <param name="model">
///     The current state or model.
/// </param>
///
/// <param name="dispatchMsg">
///     The function that allows the view to dispatch Messages.
/// </param>
let view
    (optionalUser : AuthenticatedUser option)
    (model : Model)
    (dispatch : Msg -> unit)
    (onSignOut : unit -> unit) =

    StandardTemplate.template [
        Heading.h1 [] [ str "Terms and Conditions of Use" ]
        Content.content [] [
            ol [] [
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                        str "About the Website"
                    ]
                    Content.Ol.ol [] [
                        li [] [ str
                            "Welcome to https://statbanana.com (the 'Website'). The Website provides
                              a software tool that allows users to create gameplay strategies.
                              (the 'Services')."
                        ]
                        li [] [ str
                            "The Website is operated by Gamurs Pty Ltd. (ACN 601 550 207). Access
                              to and use of the Website, or any of its associated Products or
                              Services, is provided by Gamurs. Please read these terms and
                              conditions (the 'Terms') carefully. By using, browsing and/or
                              reading the Website, this signifies that you have read, understood
                              and agree to be bound by the Terms. If you do not agree with the
                              Terms, you must cease usage of the Website, or any of Services,
                              immediately."
                        ]
                        li [] [ str
                            "Gamurs reserves the right to review and change any of the Terms by
                              updating this page at its sole discretion. When Gamurs updates the
                              Terms, it will use reasonable endeavours to provide you with notice
                              of updates to the Terms. Any changes to the Terms take immediate
                              effect from the date of their publication. Before you continue, we
                              recommend you keep a copy of the Terms for your records."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Acceptance of the Terms"
                    ]
                    p [] [ str
                        "You accept the Terms by remaining on the Website. You may also accept the
                          Terms by clicking to accept or agree to the Terms where this option is
                          made available to you by Gamurs in the user interface."
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [str
                        "Registration to use the Services"
                    ]
                    Content.Ol.ol [] [
                        li [] [ str
                            "In order to access the Services, you must first register for an
                              account through the Website (the 'Account')."
                        ]
                        li [] [
                            span [] [ str
                                "As part of the registration process, or as part of your continued
                                  use of the Services, you may be required to provide personal
                                  information about yourself (such as identification or contact
                                  details), including:"
                            ]
                            Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                                li [] [ str "Email address" ]
                                li [] [ str "Preferred username" ]
                                li [] [ str "Password" ]
                            ]
                        ]
                        li [] [ str
                            "You warrant that any information you give to Gamurs in the course of
                              completing the registration process will always be accurate, correct
                              and up to date."
                        ]
                        li [] [ str
                            "Once you have completed the registration process, you will be a
                              registered member of the Website ('Member') and agree to be bound by
                              the Terms."
                        ]
                        li [] [
                            span [] [ str
                                "You may not use the Services and may not accept the Terms if:"
                            ]
                            Content.Ol.ol [] [
                                li [] [ str
                                    "you are not of legal age to form a binding contract with
                                      Gamurs; or"
                                ]
                                li [] [ str
                                    "you are a person barred from receiving the Services under the
                                      laws of Australia or other countries including the country
                                      in which you are resident or from which you use the Services."
                                ]
                            ]
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Your obligations as a Member"
                    ]
                    Content.Ol.ol [] [
                        li [] [
                            span [] [ str "As a Member, you agree to comply with the following:" ]
                            Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                                li [] [
                                    span [] [ str
                                        "you will use the Services only for purposes that are
                                          permitted by:"
                                    ]
                                    Content.Ol.ol [ Content.Ol.IsLowerRoman ] [
                                        li [] [
                                            str "the Terms; and"
                                        ]
                                        li [] [
                                            str "any applicable law, regulation or generally accepted
                                                  practices or guidelines in the relevant jurisdictions;"
                                        ]
                                    ]
                                ]
                                li [] [ str
                                    "you have the sole responsibility for protecting the
                                      confidentiality of your password and/or email address. Use
                                      of your password by any other person may result in the
                                      immediate cancellation of the Services;"
                                ]
                                li [] [ str
                                    "any use of your registration information by any other person,
                                      or third parties, is strictly prohibited. You agree to
                                      immediately notify Gamurs of any unauthorised use of your
                                      password or email address or any breach of security of which
                                      you have become aware;"
                                ]
                                li [] [ str
                                    "access and use of the Website is limited, non-transferable
                                      and allows for the sole use of the Website by you for the
                                      purposes of Gamurs providing the Services;"
                                ]
                                li [] [ str
                                    "you will not use the Services or the Website in connection
                                      with any commercial endeavours except those that are
                                      specifically endorsed or approved by the management of Gamurs;"
                                ]
                                li [] [ str
                                    "you will not use the Services or Website for any illegal
                                      and/or unauthorised use which includes collecting email
                                      addresses of Members by electronic or other means for the
                                      purpose of sending unsolicited email or unauthorised framing
                                      of or linking to the Website;"
                                ]
                                li [] [ str
                                    "you agree that commercial advertisements, affiliate links,
                                      and other forms of solicitation may be removed from the
                                      Website without notice and may result in termination of the
                                      Services. Appropriate legal action will be taken by Gamurs
                                      for any illegal or unauthorised use of the Website; and"
                                ]
                                li [] [ str
                                    "you acknowledge and agree that any automated use of the
                                      Website or its Services is prohibited."
                                ]
                            ]
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                        str "Payment"
                    ]
                    Content.Ol.ol [] [
                        li [] [
                            span [] [ str
                                "Where the option is given to you, you may make payment for the
                                  Services (the ' Services Fee') by way of:"
                            ]
                            Content.Ol.ol [] [
                                li [] [ str "PayPal ('PayPal')" ]
                            ]
                        ]
                        li [] [ str
                            "All payments made in the course of your use of the Services are made
                              using Stripe and or PayPal. In using the Website, the Services or
                              when making any payment in relation to your use of the Services, you
                              warrant that you have read, understood and agree to be bound by the
                              Stripe and or PayPal terms and conditions which are available on
                              their website."
                        ]
                        li [] [ str
                            "You acknowledge and agree that where a request for the payment of the
                              Services Fee is returned or denied, for whatever reason, by your
                              financial institution or is unpaid by you for any other reason, then
                              you are liable for any costs, including banking fees and charges,
                              associated with the Services Fee."
                        ]
                        li [] [ str
                            "You agree and acknowledge that Gamurs can vary the Services Fee at
                              any time."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Refund Policy"
                    ]
                    li [] [ str
                        "Gamurs will only provide you with a refund of the Services Fee in the
                          event they are unable to continue to provide the Services or if the
                          manager of Gamurs makes a decision, at its absolute discretion, that it
                          is reasonable to do so under the circumstances (the 'Refund')."
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Copyright and Intellectual Property"
                    ]
                    Content.Ol.ol [] [
                        li [] [ str
                            "The Website, the Services and all of the related products of Gamurs
                              are subject to copyright. The material on the Website is protected
                              by copyright under the laws of Australia and through international
                              treaties. Unless otherwise indicated, all rights (including
                              copyright) in the Services and compilation of the Website (including
                              but not limited to text, graphics, logos, button icons, video
                              images, audio clips, Website, code, scripts, design elements and
                              interactive features) or the Services are owned or controlled for
                              these purposes, and are reserved by Gamurs or its contributors."
                        ]
                        li [] [
                            span [] [ str
                                "All trademarks, service marks and trade names are owned,
                                  registered and/or licensed by Gamurs, who grants to you a
                                  worldwide, non-exclusive, royalty-free, revocable license whilst
                                  you are a Member to:"
                            ]
                            Content.Ol.ol [] [
                                li [] [ str
                                    "use the Website pursuant to the Terms;"
                                ]
                                li [] [ str
                                    "copy and store the Website and the material contained in the
                                      Website in your device's cache memory; and"
                                ]
                                li [] [ str
                                    "print pages from the Website for your own personal and
                                      non-commercial use."
                                ]
                            ]
                        ]
                        li [] [ str
                            "Gamurs does not grant you any other rights whatsoever in relation to
                              the Website or the Services. All other rights are expressly reserved
                              by Gamurs."
                        ]
                        li [] [
                            span [] [ str
                                "Gamurs retains all rights, title and interest in and to the
                                  Website and all related Services. Nothing you do on or in
                                  relation to the Website will transfer any:"
                            ]
                            Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                                li [] [ str
                                    "business name, trading name, domain name, trade mark,
                                      industrial design, patent, registered design or copyright,
                                      or"
                                ]
                                li [] [ str
                                    "a right to use or exploit a business name, trading name,
                                      domain name, trade mark or industrial design, or"
                                ]
                                li [] [ str
                                    "a thing, system or process that is the subject of a patent,
                                      registered design or copyright (or an adaptation or
                                      modification of such a thing, system or process),"
                                ]
                            ]
                            span [] [ str "to you." ]
                        ]
                        li [] [ str
                            "You may not, without the prior written permission of Gamurs and the
                              permission of any other relevant rights owners: broadcast,
                              republish, up-load to a third party, transmit, post, distribute,
                              show or play in public, adapt or change in any way the Services or
                              third party Services for any purpose, unless otherwise provided by
                              these Terms. This prohibition does not extend to materials on the
                              Website, which are freely available for re-use or are in the public
                              domain."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Privacy"
                    ]
                    Content.Ol.ol [] [
                        li [] [ str
                            "Gamurs takes your privacy seriously and any information provided
                              through your use of the Website and/or Services are subject to
                              Gamurs's Privacy Policy, which is available on the Website."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "General Disclaimer"
                    ]
                    Content.Ol.ol [] [
                        li [] [ str
                            "Nothing in the Terms limits or excludes any guarantees, warranties,
                              representations or conditions implied or imposed by law, including
                              the Australian Consumer Law (or any liability under them) which by
                              law may not be limited or excluded."
                        ]
                        li [] [
                            span [] [ str
                                "Subject to this clause, and to the extent permitted by law:"
                            ]
                            Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                                li [] [ str
                                    "all terms, guarantees, warranties, representations or
                                      conditions which are not expressly stated in the Terms are
                                      excluded; and"
                                ]
                                li [] [ str
                                    "Gamurs will not be liable for any special, indirect or
                                      consequential loss or damage (unless such loss or damage is
                                      reasonably foreseeable resulting from our failure to meet an
                                      applicable Consumer Guarantee), loss of profit or
                                      opportunity, or damage to goodwill arising out of or in
                                      connection with the Services or these Terms (including as a
                                      result of not being able to use the Services or the late
                                      supply of the Services), whether at common law, under
                                      contract, tort (including negligence), in equity, pursuant
                                      to statute or otherwise."
                                ]
                            ]
                        ]
                        li [] [
                            span [] [ str
                                "Use of the Website and the Services is at your own risk.
                                  Everything on the Website and the Services is provided to you
                                  \"as is\" and \"as available\" without warranty or condition of
                                  any kind. None of the affiliates, directors, officers,
                                  employees, agents, contributors and licensors of Gamurs make any
                                  express or implied representation or warranty about the Services
                                  or any products or Services (including the products or Services
                                  of Gamurs) referred to on the Website. This includes (but is not
                                  restricted to) loss or damage you might suffer as a result of
                                  any of the following:"
                            ]
                            Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                                li [] [ str
                                    "failure of performance, error, omission, interruption,
                                      deletion, defect, failure to correct defects, delay in
                                      operation or transmission, computer virus or other harmful
                                      component, loss of data, communication line failure,
                                      unlawful third party conduct, or theft, destruction,
                                      alteration or unauthorised access to records;"
                                ]
                                li [] [ str
                                    "the accuracy, suitability or currency of any information on
                                      the Website, the Services, or any of its Services related
                                      products (including third party material and advertisements
                                      on the Website);"
                                ]
                                li [] [ str
                                    "costs incurred as a result of you using the Website, the
                                      Services or any of the products of Gamurs; and"
                                ]
                                li [] [ str
                                    "the Services or operation in respect to links which are
                                      provided for your convenience."
                                ]
                            ]
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Limitation of liability"
                    ]
                    Content.Ol.ol [] [
                        li [] [ str
                            "Gamurs' total liability arising out of or in connection with the
                              Services or these Terms, however arising, including under contract,
                              tort (including negligence), in equity, under statute or otherwise,
                              will not exceed the resupply of the Services to you."
                        ]
                        li [] [ str
                            "You expressly understand and agree that Gamurs, its affiliates,
                              employees, agents, contributors and licensors shall not be liable to
                              you for any direct, indirect, incidental, special consequential or
                              exemplary damages which may be incurred by you, however caused and
                              under any theory of liability. This shall include, but is not
                              limited to, any loss of profit (whether incurred directly or
                              indirectly), any loss of goodwill or business reputation and any
                              other intangible loss."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Termination of Contract"
                    ]
                    Content.Ol.ol [] [
                        li [] [ str
                            "The Terms will continue to apply until terminated by either you or by
                              Gamurs as set out below."
                        ]
                        li [] [
                            span [] [ str "If you want to terminate the Terms, you may do so by:" ]
                            Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                                li [] [ str
                                    "providing Gamurs with 14 days' notice of your intention to
                                      terminate; and"
                                ]
                                li [] [ str
                                    "closing your accounts for all of the services which you use,
                                      where Gamurs has made this option available to you."
                                ]
                            ]
                            p [] [ str
                                "Your notice should be sent, in writing, to Gamurs via the
                                  'Contact Us' link on our homepage."
                            ]
                        ]
                        li [] [
                            span [] [ str "Gamurs may at any time, terminate the Terms with you if:" ]
                            Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                                li [] [ str
                                    "you have breached any provision of the Terms or intend to
                                      breach any provision;"
                                ]
                                li [] [ str
                                    "Gamurs is required to do so by law;"
                                ]
                                li [] [ str
                                    "the provision of the Services to you by Gamurs is, in the
                                      opinion of Gamurs, no longer commercially viable."
                                ]
                            ]
                        ]
                        li [] [ str
                            "Subject to local applicable laws, Gamurs reserves the right to
                              discontinue or cancel your membership at any time and may suspend
                              or deny, in its sole discretion, your access to all or any portion
                              of the Website or the Services without notice if you breach any
                              provision of the Terms or any applicable law or if your conduct
                              impacts Gamurs's name or reputation or violates the rights of those
                              of another party."
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Indemnity"
                    ]
                    Content.Ol.ol [] [
                        li [] [
                            span [] [ str
                                "You agree to indemnify Gamurs, its affiliates, employees, agents,
                                  contributors, third party content providers and licensors from
                                  and against:"
                            ]
                            Content.Ol.ol [ Content.Ol.IsLowerAlpha ] [
                                li [] [ str
                                    "all actions, suits, claims, demands, liabilities, costs,
                                      expenses, loss and damage (including legal fees on a full
                                      indemnity basis) incurred, suffered or arising out of or in
                                      connection with Your Services;"
                                ]
                                li [] [ str
                                    "any direct or indirect consequences of you accessing, using
                                      or transacting on the Website or attempts to do so; and/or"
                                ]
                                li [] [ str "any breach of the Terms." ]
                            ]
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Dispute Resolution"
                    ]
                    Content.Ol.ol [] [
                        li [] [
                            Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                                "Compulsory:"
                            ]
                            p [] [ str
                                "If a dispute arises out of or relates to the Terms, either party
                                  may not commence any Tribunal or Court proceedings in relation
                                  to the dispute, unless the following clauses have been complied
                                  with (except where urgent interlocutory relief is sought)."
                            ]
                        ]
                        li [] [
                            Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                                "Notice:"
                            ]
                            p [] [ str
                                "A party to the Terms claiming a dispute ('Dispute') has arisen
                                  under the Terms, must give written notice to the other party
                                  detailing the nature of the dispute, the desired outcome and
                                  the action required to settle the Dispute."
                            ]
                        ]
                        li [] [
                            Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                                "Resolution:"
                            ]
                            p [] [ str
                                "On receipt of that notice ('Notice') by that other party, the
                                  parties to the Terms ('Parties') must:"
                            ]
                            Content.Ol.ol [] [
                                li [] [ str
                                    "Within 30 days of the Notice endeavour in good faith to
                                      resolve the Dispute expeditiously by negotiation or such
                                      other means upon which they may mutually agree;"
                                ]
                                li [] [ str
                                    "If for any reason whatsoever, 30 days after the date of the
                                      Notice, the Dispute has not been resolved, the Parties must
                                      either agree upon selection of a mediator or request that an
                                      appropriate mediator be appointed by the President of the
                                      Australian Mediation Association or his or her nominee;"
                                ]
                                li [] [ str
                                    "The Parties are equally liable for the fees and reasonable
                                      expenses of a mediator and the cost of the venue of the
                                      mediation and without limiting the foregoing undertake to
                                      pay any amounts requested by the mediator as a pre-condition
                                      to the mediation commencing. The Parties must each pay their
                                      own costs associated with the mediation;"
                                ]
                                li [] [ str
                                    "The mediation will be held in Sydney, New South Wales,
                                      Australia."
                                ]
                            ]
                        ]
                        li [] [
                            Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                                "Confidential:"
                            ]
                            p [] [ str
                                "All communications concerning negotiations made by the Parties
                                  arising out of and in connection with this dispute resolution
                                  clause are confidential and to the extent possible, must be
                                  treated as \"without prejudice\" negotiations for the purpose of
                                  applicable laws of evidence."
                            ]
                        ]
                        li [] [
                            Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                                "Termination of Mediation:"
                            ]
                            p [] [ str
                                "If 30 days have elapsed after the start of a mediation of the
                                  Dispute and the Dispute has not been resolved, either Party may
                                  ask the mediator to terminate the mediation and the mediator
                                  must do so."
                            ]
                        ]
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Venue and Jurisdiction"
                    ]
                    p [] [ str
                        "The Services offered by Gamurs is intended to be used by residents of any
                          Country. In the event of any dispute arising out of or in relation to
                          the Website, you agree that the exclusive venue for resolving any
                          dispute shall be in the courts of New South Wales, Australia."
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Governing Law"
                    ]
                    p [] [ str
                        "The Terms are governed by the laws of New South Wales, Australia. Any
                          dispute, controversy, proceeding or claim of whatever nature arising out
                          of or in any way relating to the Terms and the rights created hereby
                          shall be governed, interpreted and construed by, under and pursuant to
                          the laws of New South Wales, Australia, without reference to conflict of
                          law principles, notwithstanding mandatory rules. The validity of this
                          governing law clause is not contested. The Terms shall be binding to the
                          benefit of the parties hereto and their successors and assigns."
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Independent Legal Advice"
                    ]
                    p [] [ str
                        "Both parties confirm and declare that the provisions of the Terms are
                          fair and reasonable and both parties having taken the opportunity to
                          obtain independent legal advice and declare the Terms are not against
                          public policy on the grounds of inequality or bargaining power or
                          general grounds of restraint of trade."
                    ]
                ]
                li [] [
                    Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str
                        "Severance"
                    ]
                    p [] [ str
                        "If any part of these Terms is found to be void or unenforceable by a
                          Court of competent jurisdiction, that part shall be severed and the
                          rest of the Terms shall remain in force."
                    ]
                ]
            ]
            p [] [ str "Last Updated: 15th April 2019" ]
        ]
    ] (Some Route.Terms) optionalUser onSignOut