/// Item pane for storing... items
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Molecules.ItemPane

open System

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import
open Fable.Import.React

open Fulma
open Elmish.React

open StatBanana.Web.Client.Domain.Strategiser
open StatBanana.Web.Client.Extensions

type Styles =
    { container : string
      grid : string
      input : string
      padding : string }

type private ItemDroppedOutOfPaneHandler = MouseEvent -> Item -> unit

let private styles: Styles = importAll "./ItemPane.sass"

/// <summary>
///     Renders a grid of items
/// </summary>
///
/// <param name="numberOfColumns">
///     The number of columns for the item grid
/// </param>
/// <param name="onItemDroppedOutOfPane">
///     The function called when an item is dragged and dropped out of the pane
/// </param>
/// <param name="items">
///     A list of Items to render in the pane
/// </param>
let itemPane
    (numberOfColumns : int)
    (onItemDroppedOutOfPane : ItemDroppedOutOfPaneHandler)
    (items : Item list) : ReactElement =
    let mutable itemPaneRef : Creatable<Browser.Element> = NotCreated
    let isMouseEventNotInPane (mouseEvent : MouseEvent) : bool =
        match itemPaneRef with
        | Created itemPane ->
            mouseEvent.clientX < itemPane.getBoundingClientRect().left
        | NotCreated -> false
    let onDragStop event item =
        if isMouseEventNotInPane event then
            onItemDroppedOutOfPane event item
    let renderItem item =
        div [ ClassName "item"
              Key (Item.getName item) ] [
            DraggableItem.draggableItem onDragStop item
        ]
    let onScrollHandler (_ : UIEvent) =
        match itemPaneRef with
        | Created _ ->
            let elements =
                Fable.nodeListOfToList
                    (Browser.document.querySelectorAll ".item")
            let adjustItemTopProperty (itemElement : Browser.Element) =
                let rect = itemElement.getBoundingClientRect ()
                if rect.top > 0. then
                    Fable.setElementInlineStyleProperty
                        "top"
                        (rect.top.ToString () + "px")
                        itemElement.firstElementChild
                else
                    Fable.removeElementInlineStyleProperty
                        "top"
                        itemElement.firstElementChild
            List.iter adjustItemTopProperty elements
        | NotCreated -> ()
    let gridTemplateColumns =
        "repeat(" + (numberOfColumns.ToString ()) + ", 1fr)"
    if items.Length > 0 then
        let spacer =
            if items.Length % numberOfColumns = 1 then
                div [ Style [ Height "46px"
                              Width "1px" ] ] []
            else
                nothing
        div [ ClassName styles.grid
              OnScroll onScrollHandler
              Ref (fun elem -> itemPaneRef <- Created elem)
              Style [ GridTemplateColumns gridTemplateColumns ] ] [
              items |> List.map renderItem |> ofList
              spacer
        ]
    else str "Nothing to see here!"

/// <summary>
///     Renders a filterable item pane
/// </summary>
///
/// <param name="numberOfColumns">
///     The number of columns for the item grid
/// </param>
/// <param name="onItemDroppedOutOfPane">
///     The function called when an item is dragged and dropped out of the pane
/// </param>
/// <param name="onFilterChange">
///     The function called when the filter input is changed
/// </param>
/// <param name="filterString">
///    The current value of the filter input
/// </param>
/// <param name="items">
///     A list of Items to render in the pane
/// </param>
let filterableItemPane
    (numberOfColumns : int)
    (onItemDroppedOutOfPane : ItemDroppedOutOfPaneHandler)
    (onFilterChange : (string -> unit))
    (filterString : string)
    (items : Item list) : ReactElement =
    let filterItems filterString item =
        let toLowercase (str : string) = str.ToLower ()
        if filterString |> String.IsNullOrEmpty |> not then
            (item |> Item.getName |> toLowercase).Contains
                (toLowercase filterString)
        else true
    let filteredItems =
        List.filter (filterItems filterString) items
    let onChangeHandler (event : FormEvent) =
        onFilterChange event.Value
    div [ ClassName styles.container
          Key "filterableitempane-container" ] [
        Input.text [ Input.CustomClass styles.input
                     Input.OnChange onChangeHandler
                     Input.Placeholder "Type to search"
                     Input.Props [ AutoFocus true
                                   Key "filterableitempane-input"
                                   Helpers.valueOrDefault filterString ] ]
        itemPane numberOfColumns onItemDroppedOutOfPane filteredItems
    ]