namespace StatBanana.Domain

/// A game that is supported by the domain
type Game =
    | Dota2
module Game =
    /// All of the supported games
    let all =
        [ Dota2 ]

    /// <summary>
    ///     Get the name of the game in a pretty human readable string.
    /// </summary>
    ///
    /// <param name="game">
    ///     The game.
    /// </param>
    ///
    /// <returns>
    ///     A pretty human readable string of the game.
    /// </returns>
    let getName (game : Game) : string =
        match game with
        | Dota2 -> "Dota 2"

    /// <summary>
    ///     Get the name of the game in a pretty human readable abbreviated string.
    /// </summary>
    ///
    /// <param name="game">
    ///     he game.
    /// </param>
    ///
    /// <returns>
    ///     A pretty human readable string of the game in abbreviated form.
    /// </returns>
    let getAbbreviatedName (game : Game) : string =
        match game with
        | Dota2 -> "Dota2"

    /// <summary>
    ///     Get the slug of the game.
    /// </summary>
    ///
    /// <param name="game">
    ///     The game.
    /// </param>
    ///
    /// <returns>
    ///     A slug of the game.
    /// </returns>
    let toSlug (game : Game) : string =
        match game with
        | Dota2 -> "dota2"

    /// <summary>
    ///     Gets the game from the game slug.
    /// </summary>
    ///
    /// <param name="slug">
    ///     The slug.
    /// </param>
    ///
    /// <returns>
    ///     The game.
    /// </returns>
    let fromSlug (slug : string) : Game option =
        match slug with
        | "dota2" -> Dota2 |> Some
        | _ -> None

    /// <summary>
    ///    Serialise a given game value to a string id value for persistance.
    /// </summary>
    ///
    /// <remarks>
    ///     DO NOT MODIFY THESE NAMES, they are serialised into persistent
    ///     storage and modifying them will cause deserialisation to fail for
    ///     existing data.
    /// </remarks>
    ///
    /// <param name="game">
    ///     The game to serialise to a string id value.
    /// </param>
    ///
    /// <returns>
    ///     The serialised id value of the given game.
    /// </returns>
    let toId (game : Game) : string =
        match game with
        | Dota2 -> "dota2"

    /// <summary>
    ///     Deserialise a game that was serialised using <c>toId</c>.
    /// </summary>
    ///
    /// <remarks>
    ///     DO NOT MODIFY THESE NAMES, they are serialised into persistent
    ///     storage and modifying them will cause deserialisation to fail for
    ///     existing data.
    /// </remarks>
    ///
    /// <param name="id">
    ///     The serialised game.
    /// </param>
    ///
    /// <returns>
    ///     Game, or an error message if the game could not be deserialised.
    /// </returns>
    let fromId (id : string) : Game option =
        match id with
        | "dota2" -> Dota2 |> Some
        | _ -> None
