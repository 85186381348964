module StatBanana.Web.Client.Components.Molecules.FlashyCTA

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open Fulma

open StatBanana.Domain
open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Domain

type Styles =
    { hook : string
      lead : string }

/// <summary>
///     Renders a large panel with an image background and a CTA button
///     in the middle.
/// </summary>
///
/// <param name="lead">
///     The main title of the panel.
/// </param>
///
/// <param name="href">
///     The link that the CTA goes to.
/// </param>
///
/// <param name="action">
///     The text displayed on the CTA.
/// </param>
///
/// <param name="backgroundURL">
///     The URL for the image background of the panel.
/// </param>
///
/// <returns>
///     The ReactElement of the rendered hook.
/// </returns>
let flashyCTA
    (lead : string)
    (href : string)
    (action : string)
    (backgroundUrl : string)
    (backgroundTopOffset : string)
    : ReactElement =

    let styles : Styles = importAll "./FlashyCTA.sass"
    let backgroundProp =
        sprintf
            "linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url('%s')"
            backgroundUrl
    let inlineStyles =
        [ CSSProp.Background backgroundProp
          CSSProp.BackgroundPositionY backgroundTopOffset
          CSSProp.BackgroundSize "cover" ]
    div [ ClassName styles.hook
          Style inlineStyles ] [
        div [ ClassName styles.lead ] [ str lead ]
        div [] [
            Button.mainCtaButton
                Size.IsLarge
                href
                action
        ]
    ]

/// <summary>
///     Renders a large panel with an image background.
/// </summary>
///
/// <param name="lead">
///     The main title of the panel.
/// </param>
///
/// <param name="backgroundURL">
///     The URL for the image background of the panel.
/// </param>
///
/// <returns>
///     The ReactElement of the rendered hook.
/// </returns>
let flashyCTANoButton
    (lead : string)
    (backgroundUrl : string)
    (backgroundTopOffset : string)
    : ReactElement =

    let styles : Styles = importAll "./FlashyCTA.sass"
    let backgroundProp =
        sprintf
            "linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url('%s')"
            backgroundUrl
    let inlineStyles =
        [ CSSProp.Background backgroundProp
          CSSProp.BackgroundPositionY backgroundTopOffset
          CSSProp.BackgroundSize "cover" ]
    div [ ClassName styles.hook
          Style inlineStyles ] [
        div [ ClassName styles.lead ] [ str lead ]
    ]

module Vertical =
    let overwatch () =
        flashyCTA
            "Also available for Overwatch."
            "https://overwatch.statbanana.com"
            "See Pricing Plans"
            "assets/hooks/owhook.jpg"
            "8%"

module Horizontal =
    let dota2 () =
        flashyCTA
            "Now released for Dota 2."
            (None |> Route.SignUp |> Route.getPath)
            "Try It Now"
            "assets/hooks/dota2hook.jpg"
            "65%"

    let leagueOfLegendsNoBg () =
        flashyCTANoButton
            "League of Legends coming soon."
            ""
            ""

    let overwatch () =
        flashyCTA
            "Also available for Overwatch."
            "https://overwatch.statbanana.com"
            "See Pricing Plans"
            "assets/hooks/owhook.jpg"
            "10%"

    let overwatchNoBg () =
        flashyCTA
            "StatBanana for Overwatch is still available on our old site."
            "https://overwatch.statbanana.com"
            "See Pricing Plans"
            ""
            ""