namespace StatBanana.Web.Client.Domain.Strategiser

open System

type BaseNotification =
    { id : Guid
      message : string
      title : string option
      timeout : float option }

type Notification =
    | DefaultNotification of BaseNotification
    | ErrorNotification of BaseNotification
module Notification =
    /// <summary>
    ///     Gets the id for a given notification.
    /// </summary>
    ///
    /// <param name="notification">
    ///     The notification to get the id for.
    /// </param>
    ///
    /// <returns>
    ///     The id of a given notification.
    /// </returns>
    let getId (notification : Notification) : Guid =
        match notification with
        | DefaultNotification notification
        | ErrorNotification notification ->
            notification.id

    /// <summary>
    ///     Gets the message for a given notification.
    /// </summary>
    ///
    /// <param name="notification">
    ///     The notification to get the message for.
    /// </param>
    ///
    /// <returns>
    ///     The message of a given notification.
    /// </returns>
    let getMessage (notification : Notification) : string =
        match notification with
        | DefaultNotification notification
        | ErrorNotification notification ->
            notification.message

    /// <summary>
    ///     Gets the title for a given notification.
    /// </summary>
    ///
    /// <param name="notification">
    ///     The notification to get the title for.
    /// </param>
    ///
    /// <returns>
    ///     The title of a given notification.
    /// </returns>
    let getTitle (notification : Notification) : string option =
        match notification with
        | DefaultNotification notification
        | ErrorNotification notification ->
            notification.title