namespace StatBanana.Dto.Firestore.Subscriptions

open Fable.Core.JsInterop

open StatBanana.Domain
open StatBanana.Utils

/// Field definitions for Firestore Plan DTOs.
[<RequireQualifiedAccess>]
module PlanDto =
    module Plan =
        let fromDomain (plan : Plan) : obj =
            createObj [
                "package" ==>
                    (plan.package |> PackageDto.PremiumPackage.fromDomain)
                "billingPeriod" ==>
                    (plan.billingPeriod |> BillingPeriodDto.BillingPeriod.fromDomain)
            ]

        let toDomain (plan : obj) : Plan =
            { package =
                plan
                |> JsonAdapter.getString "package"
                |> PackageDto.PremiumPackage.toDomain
              billingPeriod =
                plan
                |> JsonAdapter.getString "billingPeriod"
                |> BillingPeriodDto.BillingPeriod.toDomain }