/// Renders an auth form.
[<RequireQualifiedAccess>]
module StatBanana.Web.Client.Components.Molecules.AuthForm

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open Fulma

open StatBanana.Web.Client.Components.Atoms

type Styles =
    { container : string
      description : string
      loading : string
      link : string
      title : string }

/// <summary>
///     Renders an auth form.
/// </summary>
///
/// <param name="onAuthComponentRendered">
///     Callback for when the auth component is rendered.
/// </param>
///
/// <param name="title">
///     The title of the form.
/// </param>
///
/// <param name="description">
///     The description of the auth form.
/// </param>
///
/// <param name="formLink">
///     The link that will be rendered at the bottom of the form
/// </param>
///
/// <returns>
///     The rendered auth form.
/// </returns>
let authForm
    (isUIPendingRedirect : bool)
    (onAuthComponentRendered : string -> unit)
    (title : string)
    (description : string)
    (formLink : ReactElement)
    : ReactElement =

    let styles : Styles = importAll "./AuthForm.sass"
    let loadingOrPreamble =
        if isUIPendingRedirect then
            div [ ClassName styles.loading ] [
                Loading.loading ()
            ]
        else
            fragment [] [
                h2 [ ClassName styles.title ] [
                    str title
                ]
                p [ ClassName styles.description ] [
                    str description
                ]
            ]
    let formLink =
        if isUIPendingRedirect then
            nothing
        else
            div [ ClassName styles.link ] [
                formLink
            ]
    Box.box' [ CustomClass styles.container ] [
        loadingOrPreamble
        AuthContainer.authContainer
            "auth-container"
            onAuthComponentRendered
        formLink
    ]