namespace StatBanana.Web.Client.Services.Analytics

open Fable.Core.JsInterop

open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Import.ReactGA

/// <summary>
///     Service responsible for reporting events to Google Analytics
/// </summary>
///
/// <remarks>
///     Each GA Tracker to be reported to via this service must be manually configured to:
///         - enable userId tracking
///         - enable session unification
///         - setup the CustomDimensions defined in this module
///
///    <note type="caution">
///        Window titles and URLs containing Personally Identifiable Information (PII)
///        must not be sent to Google Analytics.  Sending any PII whatsoever to Google
///        Analytics is a violation of their ToS.
///     </note>
/// </remarks>
module GoogleAnalytics =

    // Property type for sending userId to GA
    type private GaUserIdProperty = { userId: string }

    /// Custom Dimensions for the "GamerAI" Google Analytics App
    ///
    /// Note: these must be configured manually for each Google Analytics "property/tracker" we
    /// want to report to.
    module private CustomDimensions =
        type private Dimension1Property = { dimension1: string }
        type private Dimension2Property = { dimension2: string }

        /// Set "PageType" dimension based on the current route.
        let setPageType (route: Route): unit =
            let pageType = PageType.fromRoute route
            ReactGA.set ({ dimension1 = pageType })

        /// Set "PageType" dimension based on the current route.
        let setPlatform (): unit =
            ReactGA.set ({ dimension2 = AnalyticsPlatform.web })

    /// Initialise a Google Analytics tracker.  This function MUST be called before any of the other
    /// tracking functions will record any data.
    let private initialise (gaTrackingId: string) (options: InitializeOptions option): unit =
        match options with
        | Some opts -> ReactGA.initialize (gaTrackingId, opts)
        | None -> ReactGA.initialize (gaTrackingId)

        // Set a platform=web custom dimension for all events
        CustomDimensions.setPlatform ()

    /// <summary>
    ///     Set userId for Google Analytics, and send an authentication event to ensure the id is
    ///     transmitted immediately.
    /// </summary>
    ///
    /// <remarks>
    ///     Analytics events for the current session send before this function is called will
    ///     still be associated with this user-id thanks to "session unification".
    ///
    ///     For details see:
    ///
    ///     https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id
    ///     https://support.google.com/analytics/answer/4574780
    /// </remarks>
    let private setUserId (userId: string): unit =
        // Set userId, this doesn't send any data to Google Analytics
        ReactGA.set ({ userId = userId })

        // Send an event to ensure the userId is transmitted to Google Analytics:
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id
        let event = createEmpty<EventArgs>
        event.category <- "authentication"
        event.action <- "user-id available"
        // Set as a non-interaction event to avoid skewing bounce-rate data
        event.nonInteraction <- Some true
        ReactGA.event event

    /// Send an event reporting that the current user signed up
    let private userSignedUp (): unit =
        let event = createEmpty<EventArgs>
        event.category <- "authentication"
        event.action <- "signed up"
        ReactGA.event event

    /// Send an event reporting that the current user has subscribed to premium
    let private userSubscribed (): unit =
        let event = createEmpty<EventArgs>
        event.category <- "premium"
        event.action <- "subscribed"
        ReactGA.event event

    /// Send a pageview event to Google analytics for the specified route
    let private pageview (route: Route): unit =
        CustomDimensions.setPageType route
        let path = Route.getPath route
        ReactGA.pageview (path)

    /// Send a user action to Google Analytics
    let private strategiserAction (action: Strategiser.UserAction): unit =
        let args = createEmpty<EventArgs>
        let actionData = action |> Strategiser.UserAction.getData
        match action, actionData with
        | Strategiser.UserAction.DroppedMarkerOnMap _, Strategiser.UserActionData.DroppedMarkerData data ->
            args.category <- "Strategiser"
            args.action <- action |> Strategiser.UserAction.getName
            args.label <- data.game + ", " + data.``type`` |> Some
            ReactGA.event args

        | Strategiser.UserAction.ClickedShareSession, _
        | Strategiser.UserAction.EndedCollaborationSession _, _
        | Strategiser.UserAction.SavedSession, _
        | Strategiser.UserAction.StartedCollaborationSession, _
        | Strategiser.UserAction.ToggledSessionPrivacy _, _ ->
            args.category <- "Strategiser"
            args.action <- action |> Strategiser.UserAction.getName
            ReactGA.event args

        | Strategiser.UserAction.DroppedMarkerOnMap _, _ -> ()

    /// <summary>
    ///     Send an error message to Google Analytics as an exception.
    /// </summary>
    ///
    /// <param name="description">
    ///     Description of the exception, send to Google Analytics
    /// </param>
    ///
    /// <param name="fatal">
    ///     Was the exception fatal?
    /// </param>
    let private sendError (description: string) (fatal: bool): unit =
        // Send the exception or error to Google Analytics:
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/exceptions
        let details = createEmpty<ExceptionArgs>
        details.description <- Some description
        details.fatal <- Some fatal
        ReactGA.``exception`` details

    /// <summary>
    ///     Send an exception to Google Analytics.
    /// </summary>
    ///
    /// <param name="ex">
    ///     The exception, send to Google Analytics
    /// </param>
    ///
    /// <param name="fatal">
    ///     Was the exception fatal?
    /// </param>
    let private sendException (ex: exn) (fatal: bool): unit =
        let description = ex.ToString()
        sendError description fatal

    /// Send an invalid route to Google Analytics
    let private invalidRoute (route: string): unit =
        let description = sprintf "Invalid Route: %s" route
        sendError description false

    /// Send the provided analytics event to Google Analytics
    let private track (event: AnalyticsEvent): unit =
        match event with
        | InvalidRoute invalidPath -> invalidRoute invalidPath
        | PageView route -> pageview route
        | StrategiserAction action -> strategiserAction action
        | UnhandledError errorMsg -> sendError errorMsg true
        | UnhandledException ex -> sendException ex true
        | UserIdAvailable userId -> setUserId userId
        | UserSignedUp _ -> userSignedUp ()
        | UserSubscribed _ -> userSubscribed ()

    /// <summary>
    ///     Initialise a Google Analytics analytics provider for the
    ///     AnalyticsService.
    /// </summary>
    ///
    /// <param name="env">
    ///     Deployment environment, used to determine which tracker to send
    ///     events to.
    /// </param>
    ///
    /// <returns>
    ///     Google Analytics provider.
    /// </returns>
    let provider (env: DeploymentEnvironment): AnalyticsProvider =
        let trackerId =
            match env with
            | DeploymentEnvironment.LocalDevelopment
            | DeploymentEnvironment.Development -> "UA-127732647-2"
            | DeploymentEnvironment.Production -> "UA-127732647-3"

        // Initialise the GA client
        initialise trackerId None

        // Disable tracking on local dev
        if env = DeploymentEnvironment.LocalDevelopment
        then Fable.Import.Browser.window?("ga-disable-UA-127732647-2") <- true

        // Provide analytics event handler
        track
