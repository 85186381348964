module StatBanana.Web.Client.Components.Organisms.LandingHero

open Fable.Core.JsInterop
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Fable.Import.React

open Fulma

open StatBanana.Domain
open StatBanana.Web.Client.Components.Atoms
open StatBanana.Web.Client.Components.Molecules
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Domain
open StatBanana.Web.Client.Import

type Styles =
    { black : string
      cta : string
      ctaHint : string
      hero : string
      subtitle : string
      wheat : string }

let private styles : Styles = importAll "./LandingHero.sass"

let private body optionalUser optionalProfile =
    let ctas =
        let demoAndTrial =
            Button.list [ Button.List.Option.IsCentered ] [
                Button.mainCtaButton
                    IsLarge
                    (None |> Route.SignUp |> Route.getPath)
                    "Try Now"
                Button.secondaryCtaButton
                    IsLarge
                    (Route.Demo Game.Dota2 |> Route.getPath)
                    "Demo"
            ]

        match optionalUser with
        | Some user ->
            if user.claims |> UserClaim.isSubscribed Game.Dota2 then
                Button.mainCtaButton
                    IsLarge
                    ((Game.Dota2, None) |> Route.Strategiser |> Route.getPath)
                    "Use StatBanana"
            else
                demoAndTrial
        | None ->
            demoAndTrial

    let subtitleClassNames =
        ClassNames.classNames
            [ (styles.subtitle, true); (styles.wheat, true) ]

    Hero.body [] [
        Container.container [
            Container.Modifiers
                [ Modifier.TextAlignment
                    (Screen.All, TextAlignment.Centered)
                ]
            ] [
            Heading.h1 [ Heading.Option.CustomClass styles.wheat ] [
                str "The Ultimate Strategy Planning Tool"
            ]
            p [ ClassName subtitleClassNames ] [
                str "Giving your team the power to prepare for anything."
            ]
            div [ ClassName styles.cta ] [
                ctas
            ]
        ]
    ]

/// Renders the landing page top Hero element
let hero
    (optionalUser : AuthenticatedUser option)
    (optionalProfile: UserProfile option)
    (onSignOut : unit -> unit)
    : ReactElement =

    let heroClassName =
        ClassNames.classNames
            [(styles.hero, true); (styles.black, true)]
    Hero.hero [ Hero.CustomClass heroClassName
                Hero.IsMedium
                Hero.Props
                    [ Id "Hero"
                      Style
                        [ CSSProp.Background
                            "radial-gradient(ellipse at center, rgba(0, 0, 0, 0.3) 30%,rgba(0, 0, 0, 1) 100%), url('assets/landing/lc_bg.jpg')"
                          CSSProp.BackgroundSize "cover" ] ] ] [
        Hero.head [] [
            NavigationHeader.header
                (Some Route.Landing)
                true
                optionalUser
                onSignOut
        ]
        (body optionalUser optionalProfile)
    ]