namespace StatBanana.Web.Client.Dto.Database.Strategiser

open StatBanana.Web.Client.Domain
open StatBanana.Utils

/// Field definitions for Firestore PlaybackState DTOs.
[<RequireQualifiedAccess>]
module PlaybackStateDto =

    module PlaybackState =

        let fromDomain (playbackState : Strategiser.PlaybackState) : string =
            match playbackState with
            | Strategiser.Paused -> "paused"
            | Strategiser.Playing -> "playing"

        let toDomain (playbackState : string) : Strategiser.PlaybackState =
            match playbackState with
            | "paused" -> Strategiser.Paused
            | "playing" -> Strategiser.Playing
            | unexpectedPlaybackState ->
                sprintf "Unexpected PlaybackState DTO item: %s" unexpectedPlaybackState
                |> JsonAdapter.JsonParsingException
                |> raise