namespace StatBanana.Web.Client.Domain.Dota2

/// A number representing in-game distance in Dota 2
type Distance = private Distance of int
module Distance =
    /// <summary>
    ///    Converts a given int into a Dota 2 distance.
    /// </summary>
    ///
    /// <param name="value">
    ///     The int to convert into a Dota 2 distance for.
    /// </param>
    ///
    /// <returns>
    ///     If positive int, the converted Dota 2 distance, otherwise None.
    /// </returns>
    let fromInt (value : int) : Distance option =
        if value >= 0 then
            value
            |> Distance
            |> Some
        else
            None

    /// <summary>
    ///    Converts a given int into a Dota 2 distance
    /// </summary>
    ///
    /// <param name="value">
    ///     The int to convert into a Dota 2 distance for.
    /// </param>
    ///
    /// <returns>
    ///     If positive int, the converted Dota 2 distance, otherwise raises exception.
    /// </returns>
    let fromIntLiteral (value : int) : Distance =
        match fromInt value with
        | Some value ->
            value
        | None ->
            System.ArgumentException "Not a valid value"
            |> raise

    /// <summary>
    ///    Converts a Dota 2 distance into an int
    /// </summary>
    ///
    /// <param name="distance">
    ///     The Dota 2 distance to convert into an int for.
    /// </param>
    ///
    /// <returns>
    ///    The given Dota 2 distance as an int.
    /// </returns>
    let toInt (distance : Distance) : int =
        let (Distance distanceInt) = distance
        distanceInt

    /// <summary>
    ///    Converts a Dota 2 distance into a float
    /// </summary>
    ///
    /// <param name="distance">
    ///     The Dota 2 distance to convert into a float for.
    /// </param>
    ///
    /// <returns>
    ///    The given Dota 2 distance as a float.
    /// </returns>
    let toFloat (distance : Distance) : float =
        let (Distance distanceInt) = distance
        float distanceInt

    /// <summary>
    ///    Checks if a given Dota 2 distance is not equal to zero (0)
    /// </summary>
    ///
    /// <param name="distance">
    ///     The Dota 2 distance to check if not equal to zero for.
    /// </param>
    ///
    /// <returns>
    ///    Whether the given Dota 2 distance is not equal to zero.
    /// </returns>
    let isNotZero (distance : Distance) : bool =
        distance > fromIntLiteral 0

/// A number representing an in-game coordinate in Dota 2
type Coordinate = private Coordinate of float
module Coordinate =
    /// <summary>
    ///    Convert a given Dota 2 coordinate to a float.
    /// </summary>
    ///
    /// <param name="coordinate">
    ///     The Dota 2 coordinate to convert to a float for.
    /// </param>
    ///
    /// <returns>
    ///     The given Dota 2 coordinate as a float.
    /// </returns>
    let toFloat (coordinate : Coordinate) : float =
        let (Coordinate coordinateFloat) = coordinate
        coordinateFloat

/// A record representing in-game coordinates in Dota 2
type Coordinates =
    { x : Coordinate
      y : Coordinate }